import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

const getAPIEndpoint = () => {
  const urlParams = new URLSearchParams(window.location.search);
  const env = urlParams.get('env');
  const uri = urlParams.get('uri');
  
  if (env === 'local' && uri) {
    console.log("Local environment detected. Using provided URI:", uri);
    return uri + "/sfims";
  } else if (env === 'demo') {
    return "https://demo-ims-api.instaledgerx.com" + "/sfims";
  }
  return 'https://prod-ims-api.instaledgerx.com' + "/sfims";
};

function Bill() {
  const { billHashId } = useParams();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [pdfUrl, setPdfUrl] = useState(null);
  const [pdfLoadError, setPdfLoadError] = useState(false);
  const [objectFailed, setObjectFailed] = useState(false);
  const [iframeFailed, setIframeFailed] = useState(false);

  useEffect(() => {
    const fetchBill = async () => {
      try {
        const apiEndpoint = getAPIEndpoint();
        const response = await fetch(`${apiEndpoint}/bill/${billHashId}`);
        const data = await response.json();

        if (data.success && data.data) {
          setPdfUrl(data.data);
        } else {
          setError('Bill not found');
        }
      } catch (err) {
        setError('Failed to fetch bill');
      } finally {
        setLoading(false);
      }
    };

    fetchBill();
  }, [billHashId]);

  if (loading) {
    return (
      <div className="bill-container">
        <div className="loading">Loading...</div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="bill-container">
        <div className="error-message">{error}</div>
      </div>
    );
  }

  return (
    <div className="bill-container">
      <div className="pdf-viewer">
        {!objectFailed ? (
          <object
            data={pdfUrl}
            type="application/pdf"
            width="100%"
            height="800px"
            onError={() => setObjectFailed(true)}
          >
            {!iframeFailed ? (
              <iframe
                src={pdfUrl}
                width="100%"
                height="800px"
                onError={() => setIframeFailed(true)}
                style={{ border: 'none' }}
              />
            ) : (
              <div className="pdf-fallback">
                <p>PDF viewer is not supported on your device.</p>
                <a 
                  href={pdfUrl}
                  target="_blank" 
                  rel="noopener noreferrer"
                  className="download-button"
                >
                  Download PDF
                </a>
              </div>
            )}
          </object>
        ) : (
          !iframeFailed ? (
            <iframe
              src={pdfUrl}
              width="100%"
              height="800px"
              onError={() => setIframeFailed(true)}
              style={{ border: 'none' }}
            />
          ) : (
            <div className="pdf-fallback">
              <p>PDF viewer is not supported on your device.</p>
              <a 
                href={pdfUrl}
                target="_blank" 
                rel="noopener noreferrer"
                className="download-button"
              >
                Download PDF
              </a>
            </div>
          )
        )}
      </div>
    </div>
  );
}

export default Bill; 